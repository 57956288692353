export default [
  {
    title: 'Valid?',
    dataIndex: 'valid',
    slots: {
      customRender: 'tags',
    },
  },
  {
    title: 'Tahun',
    dataIndex: 'year',
  },
  {
    title: 'Kode Toko ',
    dataIndex: 'customer_code',
  },
  {
    title: 'Nama Toko',
    dataIndex: 'name',
  },
  {
    title: 'Max Target',
    dataIndex: 'target',
  },
  {
    title: 'Januari',
    dataIndex: 'januari',
  },
  {
    title: 'Februari',
    dataIndex: 'februari',
  },
  {
    title: 'Maret',
    dataIndex: 'maret',
  },
  {
    title: 'April',
    dataIndex: 'april',
  },
  {
    title: 'Mei',
    dataIndex: 'mei',
  },
  {
    title: 'Juni',
    dataIndex: 'juni',
  },
  {
    title: 'Juli',
    dataIndex: 'juli',
  },
  {
    title: 'Agustus',
    dataIndex: 'agustus',
  },
  {
    title: 'September',
    dataIndex: 'september',
  },
  {
    title: 'Oktober',
    dataIndex: 'oktober',
  },
  {
    title: 'November',
    dataIndex: 'november',
  },
  {
    title: 'Desember',
    dataIndex: 'desember',
  },
  {
    title: 'Package',
    dataIndex: 'package_name',
  },
  {
    title: 'Program',
    dataIndex: 'program_name',
  },
  {
    title: 'Action',
    dataIndex: 'action',
  },
]
