/* eslint-disable */

<template>
  <a-select
    :mode="mode"
    v-model:value="valueState"
    style="width: 300px"
    placeholder="Pilih Tipe"
    allow-clear
    not-found-content="Tidak ditemukan"
    option-filter-prop="label"
    option-label-prop="label"
    @search="onSearch"
    @select="() => (findText = null)"
    v-bind="$attrs"
    :show-arrow="true"
  >
    <a-select-option v-for="d in data" :key="d.id" :label="d.name">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="highlight(d.name)"></span>
      <!--eslint-enable-->
    </a-select-option>
  </a-select>
</template>

<script>
import { onMounted, ref, toRefs, watch } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'

export default {
  props: {
    value: { type: [Array, Number, Object, String], default: null },
    mode: {
      type: String,
      default: 'multiple',
    },
  },
  emits: ['update:value'],
  setup(props) {
    const data = ref([])
    const findText = ref(null)

    const fetchData = () => {
      apiClient.get('/api/filter/loyalty-package').then(response => {
        data.value = response.data
      })
    }

    onMounted(() => {
      fetchData()
    })

    const onSearch = value => {
      findText.value = value
    }

    const highlight = value => {
      return value.replace(new RegExp(findText.value, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }
    return { data, valueState: useVModel(props, 'value'), findText, highlight, onSearch }
  },
}
</script>

<style></style>
